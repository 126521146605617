import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {usePostUtil} from '../../../../services/api/hooks/use-post';
import queryKeys from '../../../../services/api/query-key-factory';
import ValueInput from '../../../../components/inputs/value-input';
import Practice from '../practice';

const Pillar = props => {
  const [properties, setProperties] = useState({
    color: props.pillar.color,
    image: props.pillar.imageUrl,
    sortOrder: props.pillar.sortOrder,
  });

  const [textProperties, setTextProperties] = useState({
    bodyText: props.pillar.bodyText,
    exceedsText: props.pillar.exceedBenchmarkText,
    notAnsweredText: props.pillar.notAnsweredText,
    underText: props.pillar.underBenchmarkText,
  });

  const savePillarProperties = usePostUtil(true, queryKeys.pillars.manage);
  const savePillarTexts = usePostUtil(true, queryKeys.pillars.manage);

  const handleSavePillarProperties = () => {
    if (properties) {
      savePillarProperties.mutate(
        {
          data: {
            properties,
          },
          url: `pillars/properties/${props.pillar.id}`,
        },
        {
          onError: (error, variables, context) => {
            props.setAlert({
              id: `alert-pillar-props-error-${props.pillar.id}`,
              isVisible: true,
              message: error.response,
              type: 'error',
            });
          },
          onSuccess: (data, variables, context) => {
            props.setAlert({
              id: `alert-pillar-props-succes-${props.pillar.id}`,
              isVisible: true,
              message: `Pillar ${props.pillar.name} properties have been saved successfully.`,
              type: 'success',
            });
          },
        },
      );
    }
  };

  const handleSavePillarTexts = () => {
    if (textProperties) {
      savePillarTexts.mutate(
        {
          data: {
            textProperties,
          },
          url: `pillars/texts/${props.pillar.id}`,
        },
        {
          onError: (error, variables, context) => {
            props.setAlert({
              id: `alert-pillar-texts-error-${props.pillar.id}`,
              isVisible: true,
              message: error.response,
              type: 'error',
            });
          },
          onSuccess: (data, variables, context) => {
            props.setAlert({
              id: `alert-pillar-texts-succes-${props.pillar.id}`,
              isVisible: true,
              message: `Pillar ${props.pillar.name} texts have been saved successfully.`,
              type: 'success',
            });
          },
        },
      );
    }
  };

  return (
    <div className="item__wrap" data-id={props.pillar.id}>
      <h2>{props.pillar.name}</h2>
      <div className="item-section__wrap">
        <h3>Manage Texts</h3>
        <ValueInput
          name="bodyText"
          setValues={setTextProperties}
          title="Body Text"
          value={props.pillar.bodyText}
        />
        <ValueInput
          name="exceedsText"
          setValues={setTextProperties}
          title="Exceeds Text"
          value={props.pillar.exceedBenchmarkText}
        />
        <ValueInput
          name="underText"
          setValues={setTextProperties}
          title="Under Text"
          value={props.pillar.underBenchmarkText}
        />
        <ValueInput
          name="notAnsweredText"
          setValues={setTextProperties}
          title="Not Answered Text"
          value={props.pillar.notAnsweredText}
        />
        <div>
          <button onClick={handleSavePillarTexts}>
            Save {props.pillar.name} Texts
          </button>
        </div>
      </div>
      <div className="item-section__wrap">
        <h3>Manage Properties</h3>
        <div className="item-props-section__wrap">
          <ValueInput
            name="color"
            setValues={setProperties}
            title="Color"
            value={props.pillar.color}
          />
          <ValueInput
            name="image"
            setValues={setProperties}
            title="Image"
            value={props.pillar.imageUrl}
          />
          <ValueInput
            name="sortOrder"
            setValues={setProperties}
            title="Sort Order"
            value={props.pillar.sortOrder}
          />
          <div>
            <button onClick={handleSavePillarProperties}>
              Save {props.pillar.name} Properties
            </button>
          </div>
        </div>
      </div>
      <div className="item-section__wrap">
        <h3>Practice Data</h3>
        {props.pillar.practices.map((practice, index) => {
          return (
            <Practice
              key={practice.id}
              practice={practice}
              setAlert={props.setAlert}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Pillar;

Pillar.propTypes = {
  pillar: PropTypes.shape({
    bodyText: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    exceedBenchmarkText: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    notAnsweredText: PropTypes.string.isRequired,
    practices: PropTypes.array.isRequired,
    setAlert: PropTypes.func,
    sortOrder: PropTypes.number,
    underBenchmarkText: PropTypes.string.isRequired,
  }),
};
