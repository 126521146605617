import {useMutation, useQueryClient} from 'react-query';
import {fetchClient} from '../fetch-client';

const postUtil = async params => {
  const dispatch = params.dispatch;

  if (dispatch) {
    dispatch({type: params.dispatchers.load});
  }

  const results = fetchClient.post(params.url, params.data);

  return results;
};

const postFileUtil = async params => {
  const dispatch = params.dispatch;

  if (dispatch) {
    dispatch({type: params.dispatchers.load});
  }

  const results = await fetchClient.postFile(params.url, params.data);

  return results;
};

const usePostUtil = (invlidate = false, invalidateKey = '') => {
  const queryClient = useQueryClient();

  return useMutation(params => postUtil(params), {
    onError: (error, params, context) => {
      const dispatch = params.dispatch;
      if (dispatch) {
        dispatch({
          payload: params.data,
          type: params.dispatchers.error,
          error: error.response,
          context: context,
        });
      }
    },
    onSuccess: (data, params, context) => {
      const dispatch = params.dispatch;
      if (dispatch) {
        dispatch({type: params.dispatchers.success, payload: data});
      }

      if (invlidate) {
        queryClient.invalidateQueries(invalidateKey);
      }
    },
  });
};

const usePostFileUtil = () => {
  return useMutation(params => postFileUtil(params), {
    onError: (error, params, context) => {
      const dispatch = params.dispatch;
      if (dispatch) {
        dispatch({
          payload: params.data,
          type: params.dispatchers.error,
          error: error.response,
          context: context,
        });
      }
    },
    onSuccess: (data, params, context) => {
      const dispatch = params.dispatch;
      if (dispatch) {
        dispatch({type: params.dispatchers.success, payload: data});
      }
    },
  });
};

export {usePostUtil, usePostFileUtil};
