const getCookie = name => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const setCookie = (name, value, expirationInDays = null) => {
  if (expirationInDays) {
    const currentDate = new Date();
    currentDate.setTime(
      currentDate.getTime() + expirationInDays * 24 * 60 * 60 * 100,
    );
    const expires = 'expires=' + currentDate.toUTCString();
    document.cookie = `${name}=${value};${expires}`;
  } else {
    document.cookie = `${name}=${value}`;
  }
};

const cookieUtil = {
  getCookie: name => {
    return getCookie(name);
  },
  setCookie: (name, value, expirationInDays = null) => {
    return setCookie(name, value, expirationInDays);
  },
};

export default cookieUtil;
