import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import {AuthProvider} from './services/auth/auth-provider';
import {useAuth} from './services/auth/auth-provider';
import App from './App';
import Login from './pages/Login';

export default function AppRoutes() {
  const RequireAuth = ({children}) => {
    const {isAuthenticated} = useAuth();
    const {checkAuth} = useAuth();
    const location = useLocation();

    return isAuthenticated || checkAuth() === true ? (
      children
    ) : (
      <Navigate to="/login" replace state={{path: location.pathname}} />
    );
  };
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <App />
              </RequireAuth>
            }></Route>
          <Route path="/login" element={<Login />}></Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}
