import React, {useState} from 'react';
import {usePostUtil} from '../../../../services/api/hooks/use-post';
import queryKeys from '../../../../services/api/query-key-factory';
import ValueInput from '../../../../components/inputs/value-input';
import PropTypes from 'prop-types';

const Score = props => {
  const [properties, setProperties] = useState({
    maxScore: props.score.maxScore,
    minScore: props.score.minScore,
    points: props.score.points,
  });

  const saveScoreProperties = usePostUtil(true, queryKeys.scores.manage);

  const handleSaveScoreProperties = () => {
    if (properties) {
      saveScoreProperties.mutate(
        {
          data: {
            properties,
          },
          url: `scores/${props.score.id}`,
        },
        {
          onError: (error, variables, context) => {
            props.setAlert({
              id: `alert-score-error-${props.score.id}`,
              isVisible: true,
              message: error.response,
              type: 'error',
            });
          },
          onSuccess: (data, variables, context) => {
            props.setAlert({
              id: `alert-score-succes-${props.score.id}`,
              isVisible: true,
              message: `Scores have been saved successfully.`,
              type: 'success',
            });
          },
        },
      );
    }
  };
  return (
    <div>
      <div className="practice-section__wrap" data-id={props.score.id}>
        <ValueInput
          name="maxScore"
          setValues={setProperties}
          title="Max Score"
          value={props.score.maxScore}
        />
        <ValueInput
          name="minScore"
          setValues={setProperties}
          title="Min Score"
          value={props.score.minScore}
        />
        <ValueInput
          name="points"
          setValues={setProperties}
          title="Points"
          value={props.score.points}
        />
        <div className="item-input__wrap">
          <button
            className="item-input__input"
            onClick={handleSaveScoreProperties}>
            Save Scores
          </button>
        </div>
      </div>
    </div>
  );
};

export default Score;

Score.propTypes = {
  score: PropTypes.shape({
    id: PropTypes.number.isRequired,
    maxScore: PropTypes.string.isRequired,
    minScore: PropTypes.string.isRequired,
    points: PropTypes.string.isRequired,
    setAlert: PropTypes.func,
  }),
};
