import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const ValueInput = props => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = event => {
    setInputValue(event.target.value);

    props.setValues(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    // If using an array
    // props.setValues(prevState =>
    //   prevState.map(item =>
    //     item.name === event.target.name
    //       ? {...item, value: event.target.value}
    //       : item,
    //   ),
    // );
  };

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    }
  }, [props.value]);

  return (
    <div className="item-input__wrap">
      <h4>{props.title}</h4>
      <div>
        <input
          className="value-input__input"
          value={inputValue}
          name={props.name}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ValueInput;

ValueInput.propTypes = {
  name: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};
