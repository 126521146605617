import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {usePostUtil} from '../../../../services/api/hooks/use-post';
import queryKeys from '../../../../services/api/query-key-factory';
import ValueInput from '../../../../components/inputs/value-input';
import Score from '../score';

const Practice = props => {
  const [properties, setProperties] = useState({
    benchmark: props.practice.benchmark,
    bestText: props.practice.bestText,
  });

  const savePracticeProperties = usePostUtil(true, queryKeys.practices.manage);

  const handleSavePracticeProperties = () => {
    if (properties) {
      savePracticeProperties.mutate(
        {
          data: {
            properties,
          },
          url: `practices/${props.practice.id}`,
        },
        {
          onError: (error, variables, context) => {
            props.setAlert({
              id: `alert-practice-error-${props.practice.id}`,
              isVisible: true,
              message: error.response,
              type: 'error',
            });
          },
          onSuccess: (data, variables, context) => {
            props.setAlert({
              id: `alert-practice-succes-${props.practice.id}`,
              isVisible: true,
              message: `Practice ${props.practice.name} has been saved successfully.`,
              type: 'success',
            });
          },
        },
      );
    }
  };

  return (
    <div className="item__wrap" data-id={props.practice.id}>
      <h2>{props.practice.name}</h2>
      <div className="practice-section__wrap">
        <ValueInput
          name="benchmark"
          setValues={setProperties}
          title="Benchmark"
          value={props.practice.benchmark}
        />
        <ValueInput
          name="bestText"
          setValues={setProperties}
          title="Best Text"
          value={props.practice.bestText}
        />
        <div className="item-input__wrap">
          <button
            className="item-input__input"
            onClick={handleSavePracticeProperties}>
            Save {props.practice.name} Properties
          </button>
        </div>
      </div>

      {props.practice.scores.map((score, index) => {
        return <Score key={score.id} score={score} setAlert={props.setAlert} />;
      })}
    </div>
  );
};

export default Practice;

Practice.propTypes = {
  practice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    benchmark: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    scores: PropTypes.array.isRequired,
    setAlert: PropTypes.func,
  }),
};
