import {useQuery} from 'react-query';
import {mock} from '../mocks-factory';
import {fetchClient} from '../fetch-client';

const dispatchQueryResults = (params, results) => {
  if (params) {
    const dispatch = params.dispatch;

    if (dispatch && params.dispatchers) {
      if (results.isError) {
        if (params.dispatchers.error) {
          return dispatch({
            error: results.error,
            type: params.dispatchers.error,
          });
        }
      } else if (results.data) {
        if (params.dispatchers.success) {
          return dispatch({
            payload: results.data,
            type: params.dispatchers.success,
          });
        }
      }
    }
  }
};

const getUtil = async (url, params = null, mockOptions = null) => {
  const results = {isError: false, error: null, data: null};
  let error = null;

  let dispatch = null;

  if (params) {
    dispatch = params.dispatch;
  }

  if (dispatch && params.dispatchers && params.dispatchers.load) {
    dispatch({type: params.dispatchers.load});
  }

  if (mockOptions !== null) {
    const data = mock(mockOptions);
    error = {error: {response: {message: 'Could not get mock data'}}};

    if (!data) {
      console.log('mock data not found');
      results.isError = true;
      results.error = error;
      dispatchQueryResults(params, results);
      throw new Error(error);
    } else {
      results.data = data;
      dispatchQueryResults(params, results);
      return data;
    }
  } else {
    try {
      const data = await fetchClient.get(url);
      results.data = data;
      dispatchQueryResults(params, results);
      return results.data;
    } catch (err) {
      results.isError = true;
      results.error = err;
      dispatchQueryResults(params, results);
      throw new Error(err);
    }
  }
};

const useGetUtil = (
  key,
  url,
  enabled = true,
  filter = null,
  params = null,
  mockOptions = null,
) => {
  return useQuery(key, () => getUtil(url, params, mockOptions), {
    enabled: enabled,
    filter,
  });
};

const useGetUtil2 = (
  key,
  url,
  filter = null,
  params = null,
  mockOptions = null,
) => {
  return useQuery(key, () => getUtil(url, params, mockOptions), filter);
};

export {useGetUtil, useGetUtil2};
