import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../services/auth/auth-provider';

const Login = props => {
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const {login} = useAuth();
  const {state} = useLocation();

  const handleLoginPress = () => {
    const isAuthenticated = login(value);

    if (isAuthenticated) {
      navigate(state?.path || '/');
    } else {
      document.getElementById('password-input').style.borderColor = 'red';
    }
  };

  const handlePasswordChange = event => {
    document.getElementById('password-input').style.borderColor = 'grey';
    setValue(event.target.value);
  };

  return (
    <div className="login__wrap">
      <div className="login-container">
        <h3>Password</h3>
        <input
          id="password-input"
          type="text"
          onChange={handlePasswordChange}
        />
        <button onClick={handleLoginPress}>Login</button>
      </div>
    </div>
  );
};

export default Login;
