// import {getUserAuthToken} from '../../utils/data/user-storage';

// NOTE: fetch with Android requires the charset to be included to post files.
const baseheaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};
// const baseURL = 'https://vaultup.azurewebsites.net/api';
/* FOR DEVELOPMENT */
const baseURL = '/api';

const fetchDelete = async endpoint => {
  const result = await runFetchInstance(endpoint, null, {
    method: 'DELETE',
  });

  return result;
};

const fetchGet = async endpoint => {
  const result = await runFetchInstance(endpoint, null, {
    method: 'GET',
  });

  return result;
};

const fetchPost = async (endpoint, body) => {
  const result = await runFetchInstance(endpoint, JSON.stringify(body), {
    method: 'POST',
  });

  return result;
};

const fetchPostFile = async (endpoint, body) => {
  const result = await runFetchInstance(endpoint, body, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8',
    },
  });

  return result;
};

const fetchPut = async (endpoint, body) => {
  const result = await runFetchInstance(endpoint, JSON.stringify(body), {
    method: 'PUT',
  });

  return result;
};

const handleResponse = async response => {
  if (response.status === 401) {
    const authError = {
      message:
        'User is not authorized for this request. Either refresh the token or sign in again.',
      status: 401,
    };

    return Promise.reject(authError);
  }

  if (response.ok) {
    const result = await parseResult(response, true);
    return result;
  } else {
    const error = await parseResult(response, false);
    const errorResponse = {
      response: error,
    };

    return Promise.reject(errorResponse);
  }
};

const parseResult = async (response, isSuccess = false) => {
  let result = {};
  let resultText = await response.text();
  try {
    result = JSON.parse(resultText);
  } catch (error) {
    result = {success: isSuccess, message: resultText};
  }

  return result;
};

// const refreshTokens = async () => {
//   const tokens = await getUserAuthToken();
//   const config = {
//     body: JSON.stringify(tokens),
//     method: 'POST',
//     headers: {
//       ...baseheaders,
//     },
//   };

//   const tokenRefreshResult = await fetch(
//     `${baseURL}/Account/RefreshToken`,
//     config,
//   ).then(async response => {
//     return handleResponse(response);
//   });

//   return tokenRefreshResult;
// };

const runFetchInstance = async (endpoint, body, {...customConfig}) => {
  const config = await setConfig(body, {...customConfig});

  const result = await fetch(`${baseURL}/${endpoint}`, config).then(
    async response => {
      // if (response.status && response.status === 401) {
      //   // TODO: Auth failed, we either need to refresh token
      //   // or logout the user and send them to the login screen
      //   return refreshTokens();
      // } else {
      return handleResponse(response);
      // }
    },
  );

  return result;
};

const setConfig = async (body, {...customConfig}) => {
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...baseheaders,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = body;
  }

  //   const tokens = await getUserAuthToken();

  //   if (tokens && tokens.token) {
  //     config.headers.Authorization = `Bearer ${tokens.token}`;
  //   }

  return config;
};

const fetchClient = {
  delete: async endpoint => {
    return fetchDelete(endpoint);
  },
  get: async endpoint => {
    return fetchGet(endpoint);
  },
  post: async (endpoint, body) => {
    return fetchPost(endpoint, body);
  },
  postFile: async (endpoint, body) => {
    return fetchPostFile(endpoint, body);
  },
  put: async (endpoint, body) => {
    return fetchPut(endpoint, body);
  },
};

export {fetchClient};
