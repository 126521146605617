import React from 'react';
import PropTypes from 'prop-types';

const Alert = props => {
  return (
    <div>
      {props.isVisible ? (
        <div className={`alert ${props.type}`}>
          <input type="checkbox" id={props.id} />
          <label className="close" title="close" htmlFor={props.id}>
            <i className="icon-remove" onClick={() => props.closeClick()}></i>
          </label>
          <p className="inner">{props.message}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Alert;

Alert.propTypes = {
  closeClick: PropTypes.func.isRequired,
  id: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'info', 'warning']).isRequired,
};
