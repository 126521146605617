import React, {createContext, useContext, useState} from 'react';
import cookieUtil from '../../../utils/cookie-util';

const AuthContext = createContext();

const useAuthenticate = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return {
    isAuthenticated,
    checkAuth() {
      const authCookie = cookieUtil.getCookie('logged_in');

      if (isAuthenticated || authCookie) {
        return true;
      }

      return false;
    },
    login(password) {
      const defaultPassword = process.env.REACT_APP_ADMIN_LOGIN_PASSWORD;

      if (password && password === defaultPassword) {
        cookieUtil.setCookie('logged_in', true);
        setIsAuthenticated(true);
        return true;
      }

      setIsAuthenticated(false);
      return false;
    },
  };
};

const AuthProvider = props => {
  const auth = useAuthenticate();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth was used outside of its provider');
  }

  return context;
};

export {AuthProvider, useAuth};
