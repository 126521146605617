import './App.scss';
import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import CxAdmin from './pages/admin/cx';
import ExAdmin from './pages/admin/ex';

class App extends React.Component {
  state = {
    selectedIndex: 0,
    data: {},
  };

  handleSelect = index => {
    this.setState({selectedIndex: index});
  };

  handleButtonClick = incomingData => {
    this.setState({selectedIndex: 0, data: incomingData});
  };
  render() {
    return (
      <div className="App">
        <Tabs
          selectedIndex={this.state.selectedIndex}
          onSelect={this.handleSelect}>
          <TabList>
            <Tab>Customer Experience</Tab>
            <Tab>Employee Experience</Tab>
          </TabList>
          <TabPanel>
            <CxAdmin />
          </TabPanel>
          <TabPanel>
            <ExAdmin />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default App;
