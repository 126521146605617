// import fandoms from '../data/mock/fandoms/fandoms.json';
// import luke from '../data/mock/profile/luke.json';
// import productDetails from '../data/mock/store/product-detail.json';
// import profileCards from '../data/mock/profile/profile-cards.json';
// import profiles from '../data/mock/profile/profiles.json';
// import shopPosts from '../data/mock/store/shop-posts.json';

const availableMocks = {
  //   fandoms: fandoms,
  //   luke: luke,
  //   productDetails: productDetails,
  //   profileCards: profileCards,
  //   profiles: profiles,
  //   shopPosts: shopPosts,
};

const mock = ({mockObject, root, objectId}) => {
  let data = mockObject;

  if (data !== null) {
    if (root) {
      data = data[root];

      if (objectId) {
        data = data.find(({id}) => id === objectId);
      }
    }
  }

  return data;
};

export {availableMocks, mock};
