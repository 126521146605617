import React, {useEffect, useState} from 'react';
import {useGetUtil} from '../../../services/api/hooks/use-get';
import queryKeys from '../../../services/api/query-key-factory';
import Alert from '../../../components/alert';
import Pillar from '../components/pillar';

const CxAdmin = props => {
  const CX_SURVEY_TYPE_ID = 1;
  const [alert, setAlert] = useState({
    id: 'alert-1',
    isVisible: false,
    message: 'This is a test',
    type: 'info',
  });
  const [pillars, setPillars] = useState([]);

  const pillarsData = useGetUtil(
    queryKeys.pillars.get,
    `get-pillars/${CX_SURVEY_TYPE_ID}`,
  );

  const handleAlertCloseClick = () => {
    console.log('alert closed clicked');
    setAlert(prevState => ({
      ...prevState,
      isVisible: false,
    }));
  };

  useEffect(() => {
    if (pillarsData && pillarsData.data) {
      setPillars(pillarsData.data);
    }
  }, [pillarsData]);

  return (
    <div className="CX">
      <Alert
        closeClick={handleAlertCloseClick}
        id={alert.id}
        isVisible={alert.isVisible}
        message={alert.message}
        type={alert.type}
      />
      <div className="header__wrap">
        <h1>Customer Experience</h1>
      </div>
      {pillars.map((pillar, index) => {
        return <Pillar key={pillar.id} pillar={pillar} setAlert={setAlert} />;
      })}
    </div>
  );
};

export default CxAdmin;
