const pillarsKeys = {
  all: ['pillars'],
  get: ['pillars', 'get'],
  manage: ['pillars', 'manage'],
  new: ['pillars', 'new'],
};

const practicesKeys = {
  all: ['practices'],
  get: ['practices', 'get'],
  manage: ['practices', 'manage'],
  new: ['practices', 'new'],
};

const scoresKeys = {
  all: ['scores'],
  get: ['scores', 'get'],
  manage: ['scores', 'manage'],
  new: ['scores', 'new'],
};

const queryKeys = {
  pillars: pillarsKeys,
  practices: practicesKeys,
  scores: scoresKeys,
};

export default queryKeys;
